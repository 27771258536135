import React from 'react';
import { Link } from 'gatsby';

import Layout from '../../components/layout';
import SEO from '../../components/seo';

const Pixeltrash = () => {
  return (
    <Layout>
      <SEO title="Pixeltrash" />
      <div className="wrapper">
        <h1>Pixeltrash</h1>
        <ul>
          <li>
            <Link to="/pixeltrash/dvd-screensaver">DVD Screensaver</Link>
          </li>
        </ul>
      </div>
    </Layout>
  );
};

export default Pixeltrash;
